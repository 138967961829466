<template>
  <div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form -->
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <!-- Create Tenant Card -->
        <b-card title="Create Tenant" class="mb-2">
          <!-- Organization name -->
          <validation-provider
            #default="validationContext"
            name="Organization name"
          >
            <b-form-group
              label="Organization name*"
              label-for="organizationName"
            >
              <b-form-input
                id="organizationName"
                v-model="form.organization"
                data-vv-rules="'required'"
                placeholder="Organization Name"
                name="Organization"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- First name -->
          <validation-provider #default="validationContext" name="First name">
            <b-form-group label="First Name*" label-for="firstName">
              <b-form-input
                id="firstName"
                v-model="form.firstName"
                data-vv-rules="'required'"
                name="First Name"
                placeholder="First Name"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Last name -->
          <validation-provider #default="validationContext" name="Last name">
            <b-form-group label="Last Name*" label-for="lastName">
              <b-form-input
                id="firstName"
                v-model="form.lastName"
                data-vv-rules="'required'"
                name="Last Name"
                placeholder="Last Name"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider #default="validationContext" name="Email">
            <b-form-group label="Email*" label-for="email">
              <b-form-input
                id="email"
                v-model="form.email"
                data-vv-rules="'required'"
                name="Email"
                placeholder="Email"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider #default="validationContext" name="Password">
            <b-form-group label="Password" label-for="password">
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="password"
                  v-model="form.password"
                  data-vv-rules="'required'"
                  name="Password"
                  maxlength="255"
                  :state="getValidationState(validationContext)"
                  :type="passwordType"
                />

                <b-input-group-append is-text>
                  <feather-icon
                    :icon="viewPasswordIcon"
                    class="cursor-pointer"
                    @click="togglePasswordType"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-card>
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            Submit
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
  import {
    BCard,
    BFormInput,
    BFormGroup,
    BButton,
    BForm,
    BFormInvalidFeedback,
    BInputGroupAppend,
    BInputGroup,
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { mapState } from 'vuex'

  export default {
    components: {
      BCard,
      BFormInput,
      BFormGroup,
      BButton,
      BForm,
      BFormInvalidFeedback,
      BInputGroupAppend,
      BInputGroup,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        form: this.getDefaultForm(),
        passwordType: 'password',
      }
    },
    computed: {
      ...mapState('organization', ['organizations']),
      viewPasswordIcon() {
        return this.passwordType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
    },
    methods: {
      // ...mapActions('setting', ['fetchSettingsIfNotLoaded']),
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null
      },
      togglePasswordType() {
        this.passwordType =
          this.passwordType === 'password' ? 'text' : 'password'
      },
      getDefaultForm() {
        return {
          organization: '',
          firstName: '',
          lastName: '',
          email: '',
          password: '',
        }
      },
      onSubmit() {
        this.$store
          .dispatch('organization/createOrganization', this.form)
          .then(() => {
            this.$toast(
              {
                component: ToastificationContent,
                position: 'top-right',
                bodyClass: 'msg-box-body',
                props: {
                  bodyClass: 'msg-box-body',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `You have successfully created an instance.\n
              The organization credentials are:
              suffix: ${this.organizations.nodes.at(-1).prefix}
              name: ${this.organizations.nodes.at(-1).organization}
              `,
                },
              },
              { timeout: false }
            )
          })
      },
    },
  }
</script>

<style lang="scss">
  .text-body {
    white-space: pre-line;
  }
</style>
